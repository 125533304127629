import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

export interface Props {
  subtitle?: string
  title?: string
}

export const Item = memo(function Item({ subtitle, title }: Props) {
  if (!subtitle || !title) {
    return null
  }

  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
    </Container>
  )
})

const Container = styled.div`
  width: 25%;
  max-width: 12.5rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  line-height: 1.875rem;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }

  @media (max-width: 1023px) {
    width: 100%;
    max-width: none;
    margin-top: 1.875rem;
  }
`

const Title = styled.div`
  font-weight: 600;
`

const Subtitle = styled.div``
