import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { IconLogo } from 'app/components/Icons'
import { Email, Phone, Pin } from 'app/components/Icons'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'
import { isMobile } from 'react-device-detect'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

export interface Props {
  address?: string
  email?: string
  image?: ImageProps
  label?: string
  phone?: string
  siteName?: string
}

export const ContactsIntro = memo(function ContactsIntro({
  address,
  email,
  image,
  label,
  phone,
  siteName,
}: Props) {
  return (
    <Container dial={4} row tag="section" wrap>
      <LeftMask />
      <RightMask dial={5}>
        <IconLogo />
      </RightMask>

      <Background>
        {image ? (
          <LazyLoadComponent>
            <Image {...image} />
          </LazyLoadComponent>
        ) : null}
      </Background>

      <Wrapper>
        {label ? (
          <FadeInUp>
            <Label>{label}</Label>
          </FadeInUp>
        ) : null}
        {siteName ? (
          <FadeInUp>
            <SiteName>
              {siteName}
              <Line />
            </SiteName>
          </FadeInUp>
        ) : null}

        <Contacts>
          {address ? (
            <FadeInUp>
              <Address dial={4} row>
                <Pin />
                {address}
              </Address>
            </FadeInUp>
          ) : null}
          {phone ? (
            <FadeInUp>
              <Anchor
                href={`tel:${phone}`}
                onClick={() => {
                  if (isMobile) {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Phone Number',
                      })
                  }
                }}
              >
                <Phone />
                {phone}
              </Anchor>
            </FadeInUp>
          ) : null}
          {email ? (
            <>
              <Divider />
              <FadeInUp>
                <Anchor
                  href={`mailto:${email}`}
                  onClick={() => {
                    typeof window.gtag !== 'undefined' &&
                      window.gtag('event', 'Click', {
                        event_category: 'Website',
                        event_label: 'Email Address',
                      })
                  }}
                >
                  <Email />
                  {email}
                </Anchor>
              </FadeInUp>
            </>
          ) : null}
        </Contacts>
      </Wrapper>
    </Container>
  )
})

const Container = styled(FlexBox)`
  padding: 10.9375rem 10.556vw 12.1875rem;
  position: relative;

  @media (max-width: 1199px) {
    padding: 7.5rem 1.875rem 5.625rem;
  }

  @media (max-width: 1023px) {
    padding-top: 5.625rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const LeftMask = styled(FlexBox)`
  width: calc(50% - 12.5rem);
  height: 50%;
  background: ${({ theme }) => theme.colors.variants.neutralDark2};
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;

  @media (max-width: 1023px) {
    display: none;
  }
`

const RightMask = styled(FlexBox)`
  width: calc(50% - 12.5rem);
  height: calc(50% - 3.125rem);
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;

  svg {
    width: auto;
    height: 5.625rem;
    fill: ${({ theme }) => theme.colors.variants.neutralLight2};
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Background = styled.div`
  width: calc(50% - 7.8vw);
  padding-bottom: 55.3%;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media (max-width: 1023px) {
    display: none;
  }
`

const Wrapper = styled.div`
  width: 50%;
  margin-left: 7.8vw;

  @media (max-width: 1023px) {
    width: 100%;
    margin-left: 0;
  }
`

const Label = styled.div`
  color: ${({ theme }) => theme.colors.variants.secondaryLight};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 1.0625rem;
  font-weight: 500;
  line-height: 1.25rem;
  margin-bottom: 0.9375rem;
  text-transform: uppercase;

  @media (max-width: 1199px) {
    font-size: 1rem;
  }
`

const SiteName = styled.h2`
  max-width: 26.875rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 3rem;
  font-weight: 500;
  line-height: 4rem;

  > div {
    margin-top: 1.25rem;
  }

  @media (max-width: 1199px) {
    font-size: 2.25rem;
    line-height: 2.875rem;
  }
`

const Contacts = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-size: 0.9375rem;
  font-weight: 500;
  line-height: 1.5rem;
  margin-top: 5.9375rem;

  svg {
    width: 1.875rem;
    height: auto;
    max-height: 2.25rem;
    fill: none;
    margin-right: 1.625rem;
    stroke: ${({ theme }) => theme.colors.variants.secondaryLight};
    stroke-width: 2;
  }

  @media (max-width: 1199px) {
    margin-top: 3.75rem;
  }
`

const Address = styled(FlexBox)`
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  margin-top: 3.125rem;

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Anchor = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.variants.neutralDark4};
  margin-top: 3.125rem;
  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 1199px) {
    margin-top: 1.875rem;
  }
`

const Divider = styled.div``
