import styled from '@emotion/styled'
import { FadeInUp } from 'app/components/Common/FadeInUp'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import React, { memo } from 'react'

import { Item } from './Item'

export interface Props {
  list: string[]
  title?: string
}

export const ContactsInfo = memo(function ContactsInfo({ list, title }: Props) {
  if (!list) {
    return null
  }

  return (
    <Container>
      {title ? (
        <FadeInUp>
          <Title>
            {title}
            <Line />
          </Title>
        </FadeInUp>
      ) : null}
      {list ? (
        <FadeInUp>
          <List dial={2} row space="between" wrap>
            {list.map((item, index) => (
              <Item key={index} {...item} />
            ))}
          </List>
        </FadeInUp>
      ) : null}
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 6.25rem 10.556vw 8rem;
  text-align: center;

  @media (max-width: 1199px) {
    padding: 4.375rem 1.875rem 5.625rem;
  }

  @media (max-width: 1023px) {
    padding: 3.75rem 1.875rem;
  }

  @media (max-width: 767px) {
    padding-right: 1.25rem;
    padding-left: 1.25rem;
  }
`

const Title = styled.h2`
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.875rem;
  margin-bottom: 5.125rem;

  > div {
    margin: 0.625rem auto 0;
  }

  @media (max-width: 1023px) {
    margin-bottom: 0;
  }
`

const List = styled(FlexBox)``
